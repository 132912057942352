$cdnURL: "https://rest-filestore.whiteclover.uk/files/whiteclover.io";
@import '../../styles/base';



.navBar {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 24px 32px;
  width: 100%;

  position: fixed;
  background: linear-gradient(180deg, #FDF6EB 0%, rgba(253, 246, 235, 0.00) 100%);

  z-index: 999;

  @media screen and (max-width: 1024px){
    position: fixed;
  }

  .desktopList {
    display: flex;
    align-items: center;

    @media screen and (max-width: 1250px) {
      display: none;
    }

    li {
      a {
        p {
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .activeP {
      //color: #FCFCFA;
      text-decoration: underline;
    }

  }
  ul {
    display: flex;
    justify-content: space-around;
    gap: 24px;

    /* Brand Color / Purple 1 */

    color: #63435B;

    text-decoration: none;
    list-style: none;
  }

  .login {
    box-sizing: border-box;

      p {
        padding: 8px 32px;
        border: 1px solid #63435B;
        border-radius: 8px;

        &:hover {
          text-decoration: none !important;
          background-color: #63435B;
          color: #FDF6EB;

        }
      }
  }

  .createwedding {
    p {
      padding: 8px 32px;
      border: 1px solid #F9AC80;

      background: #F9AC80;
      border-radius: 8px;

      flex: none;
      order: 8;
      flex-grow: 0;
      
      &:hover {
        text-decoration: none !important;
        border: 1px solid #63435B;
        background-color: #63435B;
        color: #FDF6EB;
      }
    }
  }

  .logo {
    //width: 220px;
    height: 40px;
    aspect-ratio: 5.5 / 1;

    @media screen and (min-width: 1921px) {
      //width: 11.458vw;
      height: 2.083vw;
    }
  }

}

.mobileList {
  display: flex;
  flex-direction: column;
}

.burger {
  display: none;
  cursor: pointer;
}

.burger div {
  width: 24px;
  height: 3px;
  border-radius: 2px;
  margin: 9px 0;
  background-color: #63435B;

  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

.burgerOpen div {
  background-color: white;
}

.x {
  transform: rotate(0deg) translateY(5px);
}
.y {
  opacity: 1;
}
.z {
  transform: rotate(0deg) translateY(-5px);
}

.xOpen {
  transform: rotate(-45deg) translateY(10px) translateX(-1px);
}
.yOpen {
  opacity: 0;
}
.zOpen {
  transform: rotate(45deg) translateY(-16px) translate(-6px);
}

@media screen and (max-width: 1250px) {

  .list {
    position: absolute;
    right: 0;
    min-height: 1000px;
    height: 100vh;
    width: 100vw;
    margin-top: 100px;
    //margin-bottom: 10vh;
    flex-direction: column;
    //justify-content: center;
    align-items: center;
    //background-color: #63435B;
    color: #FFF;

    gap: 16px;

    font-family: 'Didact Gothic', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 30.79px;
    line-height: 48px;
    /* identical to box height, or 156% */

    letter-spacing: -0.03em;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;

    list-style-type: none;


    .loginLi {
      margin: 24px 0 16px;

      .login {
        font-size: 21.328px;

        border: 1px solid #fff;
        border-radius: 8px;

        padding: 12px 48px;

      }
    }

    .createwedding {
      color: #63435B;

      font-size: 21.328px;

      padding: 12px 30px;
      gap: 10px;

      background: #F9AC80;
      border-radius: 8px;


      flex: none;
      order: 8;
      flex-grow: 0;

    }

  }

  .burger {
    display: block;
  }
}

// DROPDOWN MENU ==========================================================================================
.dropdown{
  &Btn {
    height: 36px;
    border-radius: 25%;

    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (min-width: 1921px) {
      height: 1.875vw;
    }
  }
  img {
    transition: filter 250ms 500ms ;
    width: 20px;
    height: 20px;

    @media screen and (min-width: 1921px) {
      width: 1.042vw;
      height: 1.042vw;
    }
  }
  div {
    a {
      &:hover {
        background-color: transparent !important;
      }
    }
  }
}

//.dropdownHover{
//  img {
//    filter: invert(1);
//    transition: filter 0ms ;
//  }
//}

.dropdownContent {
  font-family: 'Didact Gothic', sans-serif;
  position: fixed;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: fit-content;
  padding: 32px 72px;
  padding-top: 0;
  padding-right: calc(72px + 688px);
  z-index: 4;
  font-size: 16px;
  line-height: 24px;
  background-color: $brand-hover-accent;
  width: 100vw;
  transform: translateY(-100%);
  transition: transform 500ms ease-in-out;
  top: 110px;
}
.appearContent {
  transform: translateY(0);
  transition: transform 500ms ease-in-out;
}
.dropdownContent a {
  color: $main-strong-accent;
  text-align: right;
  text-decoration: none;
  display: block;

  &:hover {
    color: #835978;
  }
}

@media only screen and (max-width:1440px) {
  .dropdownContent { padding: 32px; padding-top: 0; padding-right: calc(40px + 786px); }
}
@media only screen and (max-width:1250px) {
  .dropdownContent { padding-right: calc(40px + 704px);}
}
@media only screen and (max-width:1250px) {
  .dropdownContent { display: none; }
}

// DROPDOWN MENU ==========================================================================================

.dropdownContentLang {
  display: none;
  position: absolute;
  top: 69px;
  right: 42px;
  background-color: #FDF6EB;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  //padding: 0 32px;
  border-radius: 8px;

  p {
    padding: 24px 32px;
    cursor: pointer;
    color: #835978;

    &:hover {
      color: #FDF6EB;
      background-color: #835978;
      border-radius: 8px;
    }
  }

  &Show {display:block;}
}

@media  screen and (max-width: 1919px) {
  .dropdownContentLang {
    top: 69px;
    right: 42px;
    z-index: 10;
  }
}

@media  screen and (max-width: 1250px) {
  .dropdownContentLang {
    top: 44px;
    right: auto;
    z-index: 10;
  }
}

@media  screen and (max-width: 450px) {
  .dropdownContentLang {
    top: 32px;
  }
}

.langButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: 150px;
  height: 56px;
  padding: 12px 32px;
  gap: 8px;
  font-size: 30px;

  //border: 1px solid #FDF6EB;
  border-radius: 8px;

  margin-top: 50px;
  cursor: pointer;
}

.languagesList {
  position: absolute;
  background-color: #63435B;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 8px;
  color: #F4F3F4;

  p {
    padding: 24px 32px;
    cursor: pointer;
    &:hover {
      background-color: #F4F3F4;
      color: #63435B;
      border-radius: 8px;
    }
  }
}
